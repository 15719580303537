import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(
    private http: HttpClient,
  ) {}

  authUser(params: any): Observable<any> {
    return this.http.post(environment.apiUrl + '/account/user/login', params, {
      headers: this.headers,
    });
  }

  logOut(): any {
    localStorage.removeItem('menu');
    localStorage.removeItem('user');
    window.location.reload();
  }
  checkSessionTimeout(): void { // detecta la hora de inicio de sesion y la cierra despues de 24hrs
    const userData = localStorage.getItem('user');
    if (!userData) {
      return;
    }
    const authData = JSON.parse(userData);
    const lastConnection = authData?.last_connection;
    if (!lastConnection) {
      return;
    }
    const lastConnectionDate = new Date(lastConnection);
    lastConnectionDate.setHours(lastConnectionDate.getHours() - 3);
    const currentDate = new Date();
    const diffInMinutes = Math.abs(currentDate.getTime() - lastConnectionDate.getTime()) / (1000 * 60);
    if (diffInMinutes >= 1440) {
      this.logOut();
    }
  }
}
