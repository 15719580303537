import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { es } from 'src/assets/i18n/es';
import { LoadingService } from './services/loading/loading.service';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'web-front';
  constructor(
    private config: PrimeNGConfig, 
    private router: Router,
    private authService: AuthService
  ) {
    this.router.events.subscribe(() => {
      })
      setTimeout(() => {
        if(this.router.url == "/"){
          if(localStorage.getItem('user')){
            this.router.navigate(['delivery/delivery-orders']);
          } else {
            return
          }
        }
      }, 500);
  }

  
  ngOnInit() {
    this.config.setTranslation(es);
    this.authService.checkSessionTimeout();
    // intervalo para detectar ultima conexion cada 30min
    setInterval(() => {
      this.authService.checkSessionTimeout();
    }, 30 * 60 * 1000);
  }

}
