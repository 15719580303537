import { Component, OnInit, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { NotificationService } from 'src/app/services/notification/notification.service';

@Component({
  selector: 'app-notification-button',
  templateUrl: './notification-button.component.html',
  styleUrls: ['./notification-button.component.scss'],
})
export class NotificationButtonComponent implements OnInit {
  token: any;
  userName: any;
  allInfo: any = [];
  quantity: number = 0;

  @ViewChild('notifications') notificationsPanel!: OverlayPanel;
  constructor(private notificationService: NotificationService) {
    // this.showNotifications();
  }

  ngOnInit(): void {
    this.notificationService.generateToken().subscribe({
      next: (response: any) => {
        this.token = response.token;
        this.showNotifications();
      },
    });
  }

  showNotifications() {
    this.notificationService.user(this.token).then((val: any) => {
      this.userName = val.uid;

      this.notificationService.getAll(this.userName);

      // Subscribe to the data$ observable to receive updates
      this.notificationService.data$.subscribe((items: any) => {
        this.allInfo = [];
        items.forEach((event: any) => {
          const aux = event;
          let link = '';
          let content = '';
          if (aux.type == 6) {
            let type = 'default. ';
            if (aux.content.includes('alas. ')) {
              type = 'alas. ';
            }
            content = aux.content.split(type)[0] + type;
            link = aux.content.split(type)[1];
            link = link.slice(9, -60);
          } else {
            content = aux.content;
          }
          this.allInfo.push({
            content: content,
            date: aux.timestamp.replace('T', ' '),
            type: aux.type,
            link: link,
            key: event.key,
          });
        });
        this.quantity = this.allInfo.length;
      });

      // this.notificationService
      //   .getAll(this.userName)
      //   .snapshotChanges()
      //   .subscribe({
      //     next: (response) => {
      //       this.allInfo = [];
      //       response.forEach((event: any) => {
      //         const aux = event.payload.toJSON();
      //         let link = '';
      //         let content = '';
      //         if (aux.type == 6) {
      //           let type = 'default. ';
      //           if (aux.content.includes('alas. ')) {
      //             type = 'alas. ';
      //           }
      //           content = aux.content.split(type)[0] + type;
      //           link = aux.content.split(type)[1];
      //           link = link.slice(9, -60);
      //         } else {
      //           content = aux.content;
      //         }
      //         this.allInfo.push({
      //           content: content,
      //           date: aux.timestamp.replace('T', ' '),
      //           type: aux.type,
      //           link: link,
      //           key: event.key,
      //         });
      //       });
      //       this.quantity = this.allInfo.length;
      //     },
      //   });
    });
  }

  deleteSelectedNotification(key: string) {
    this.notificationService
      .deleteSelectedNotification(this.userName, key)
      .then(() => {
        this.allInfo = this.allInfo.filter((item: any) => item.key !== key);
        this.quantity = this.allInfo.length;
        if (this.quantity == 0) {
          this.notificationsPanel.toggle(event);
        }
      })
      .catch((error: any) => {
        console.error('Error al eliminar la notificación.', error);
      });
  }

  deleteAllNotifications() {
    this.notificationService.deleteAll(this.userName).then(() => {
      this.notificationsPanel.toggle(event);
      this.quantity = 0;
      this.allInfo = [];
    });
  }
}
