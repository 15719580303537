import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guards';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./pages/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: 'public',
    loadChildren: () =>
      import('./pages/public/public.module').then(
        (m) => m.PublicModule
      ),
  },
  {
    path: 'reset-password/:token',
    loadChildren: () =>
      import('./pages/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      ),
  },
  {
    path: 'management',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/management/management.module').then(
        (m) => m.ManagementModule
      ),
  },
  {
    path: 'delivery',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/delivery/delivery.module').then((m) => m.DeliveryModule),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'label',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/label/label.module').then((m) => m.LabelModule),
  },
  {
    path: 'bulk',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/bulk/bulk.module').then((m) => m.BulkModule),
  },
  {
    path: 'reporting',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/reporting/reporting.module').then(
        (m) => m.ReportingModule
      ),
  },
  {
    path: 'configuration',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/configuration/configuration.module').then(
        (m) => m.ConfigurationModule
      ),
  },
  {
    path: 'assignments',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/assignments/assignments.module').then(
        (m) => m.AssignmentsModule
      ),
  },
  {
    path: 'daily-management',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/daily-management/daily-management.module').then(
        (m) => m.DailyManagementModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
