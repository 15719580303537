import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFireDatabase,
  AngularFireList,
} from '@angular/fire/compat/database';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private dbPath = 'events/';
  token: string = '';

  notificationsRef?: AngularFireList<any>;

  constructor(
    private db: AngularFireDatabase,
    private fs: AngularFirestore,
    private af: AngularFireAuth,
    private http: HttpClient
  ) {
    this.notificationsRef = this.db.list(this.dbPath);
  }

  generateToken() {
    let dataUser: any = localStorage.getItem('user');
    dataUser = JSON.parse(dataUser);
    const data = {
      user: dataUser.user_name.replaceAll('.', '-'),
    };
    return this.http.post(environment.apiUrl + '/firebase/token', data, {
      headers: this.headers,
    });
  }

  async user(token: string) {
    // let user = await this.af.signInWithCustomToken(token);
    await this.af
      .signInWithCustomToken(token)
      .then((userCredential: any) => {
        return userCredential.user?.uid;
      })
      .catch((error: any) => {
        console.error('Error al autenticar con custom token:', error);
      });

    let name = await this.af.currentUser;
    return name;
  }

  private dataSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  data$: Observable<any[]> = this.dataSubject.asObservable();

  
  getAll(uid:string) {
    this.db.list(`${this.dbPath}/${uid}`).valueChanges().pipe(
      tap((data:any) => {
        this.dataSubject.next(data); // Emit the data to subscribers
      }),
      catchError((error) => {
        console.error('Error fetching data:', error);
        this.dataSubject.next([]); // Handle the error by emitting an empty array
        throw error; // Re-throw the error after handling
      })
    ).subscribe();
  }

  deleteSelectedNotification(uid: string, key: string): Promise<void> {
    if (this.notificationsRef) {
      return this.db.object(this.dbPath + uid + '/' + key).remove();
    } else {
      return Promise.reject('notificationsRef no está definido');
    }
  }

  deleteAll(uid: string): Promise<void> {
    return this.db.object(this.dbPath + uid).remove();
  }
}
